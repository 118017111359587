import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FaLink, FaDownload, FaLinkSlash } from "react-icons/fa6";

import { Link } from "react-router-dom";

function Books() {
  const [searchTerm, setSearchTerm] = useState("");
  const [books, setBooks] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");

  const getData = () => {
    fetch(`https://learninghorizon.runasp.net/Api/Book/GetAll`)
      .then((res) => res.json())
      .then((data) => setBooks(data));
  };

  const getSections = () => {
    fetch(`https://learninghorizon.runasp.net/Api/Section`)
      .then((res) => res.json())
      .then((data) => setSections(data));
  };

  useEffect(() => {
    getData();
    getSections();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSectionClick = (sectionTittle) => {
    setSelectedSection(sectionTittle);
  };

  const filteredBooks = books.filter((book) => {
    const matchesSearch = book.bookTitle
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSection =
      selectedSection === "" || book.sectionTittle === selectedSection;
    return matchesSearch && matchesSection;
  });

  return (
    <>
      <div className="book-input">
        <label className="search-icon" htmlFor="search">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </label>
        <input
          className="form-control search"
          id="search"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="sections mt-4 ">
        <button
          className={`btn mt-2 me-2 ${
            selectedSection === "" ? "active" : "back"
          }`}
          onClick={() => setSelectedSection("")}
        >
          All Sections
        </button>
        {sections.map((section) => (
          <button
            key={section.sectionId}
            className={`btn mt-2 me-2 ${
              selectedSection === section.sectionTittle ? "active" : "back"
            }`}
            onClick={() => handleSectionClick(section.sectionTittle)}
          >
            {section.sectionTittle}
          </button>
        ))}
      </div>
      <div className="book mt-4">
        <div className="row">
          {filteredBooks.length > 0 ? (
            filteredBooks.map((book) => {
              const isDisabled = book.isFree === false;

              return (
                <div
                  key={book.bookId}
                  className="col-lg-12 col-md-12 col-sm-12"
                >
                  {/* Original card - visible only on large screens */}
                  <div className="card mb-4 d-none d-lg-block d-md-block">
                    <div className="d-flex align-items-center">
                      <img
                        className="img-fluid"
                        src={`https://learninghorizon.runasp.net/Api/Book/GetBookFile?path=${book.bookImageLink}`}
                        alt={book.bookTitle}
                      />
                      <div className="info ms-4">
                        <h3 className="text-capitalize">{book.bookTitle}</h3>
                        <p>{book.bookDescription}</p>
                        <p>{book.sectionTittle}</p>
                        <span className="price">
                          {book.bookPrice === "0" || book.isFree === true
                            ? "Free"
                            : `${book.bookPrice} LE`}
                        </span>
                      </div>
                      <button
                        className={`links ms-auto pe-3 btn ${
                          isDisabled ? "disabled" : ""
                        }`}
                        disabled={isDisabled}
                      >
                        {book.bookLink ? (
                          <Link
                            className="btn mb-2"
                            to={book.bookLink}
                            target="_blank"
                            rel="noopener"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <FaLink />
                              <span className="ms-2"> Explore</span>
                            </div>
                          </Link>
                        ) : (
                          <button className="btn mb-2 empty" disabled>
                            <div className="d-flex align-items-center justify-content-center">
                              <FaLinkSlash />
                              <span className="ms-2"> Empty</span>
                            </div>
                          </button>
                        )}

                        <br />
                        <Link
                          to={`https://learninghorizon.runasp.net/Api/Book/GetBookFile?path=${book.bookPath}`}
                          className="btn download"
                          download
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <FaDownload />
                            <span className="ms-2"> Download</span>
                          </div>
                        </Link>
                      </button>
                    </div>
                  </div>

                  {/* New card - visible only on small screens */}
                  <div className="card mb-4 d-block d-lg-none d-md-none text-center">
                    <div className="d-flex align-items-center flex-column">
                      <img
                        className="img-fluid"
                        src={`https://learninghorizon.runasp.net/Api/Book/GetBookFile?path=${book.bookImageLink}`}
                        alt={book.bookTitle}
                      />
                      <div className="info mt-3">
                        <h4 className="text-capitalize">{book.bookTitle}</h4>
                        <p>{book.bookDescription}</p>
                        <p>{book.sectionTittle}</p>
                        <span className="price">
                          {book.bookPrice === "0" || book.isFree === true
                            ? "Free"
                            : `${book.bookPrice} LE`}
                        </span>
                      </div>
                      <button
                        className={`links btn links mt-3 d-flex flex-column justify-content-center align-items-center ${
                          isDisabled ? "disabled" : ""
                        }`}
                        disabled={isDisabled}
                      >
                        {book.bookLink ? (
                          <Link
                            className="btn mb-3"
                            to={book.bookLink}
                            target="_blank"
                            rel="noopener"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <FaLink />
                              <span className="ms-2"> Explore</span>
                            </div>
                          </Link>
                        ) : (
                          <button className="btn empty mb-2" disabled>
                            <div className="d-flex align-items-center justify-content-center">
                              <FaLinkSlash />
                              <span className="ms-2"> Empty</span>
                            </div>
                          </button>
                        )}

                        <Link
                          to={`https://learninghorizon.runasp.net/Api/Book/GetBookFile?path=${book.bookPath}`}
                          className="btn download"
                          download
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <FaDownload />
                            <span className="ms-2"> Download</span>
                          </div>
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-12">
              <p className="text-center">
                No books found matching your search criteria.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Books;
