import "./css/registration.css";
import "./css/responsive-registration.css";
import books from "../images/books.png";
import heal from "../images/heal.png";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useEffect } from "react";

function Information() {
  const navigate = useNavigate();
  const apiUrl = `https://learninghorizon.runasp.net/Api/SignUp/create-async`;

  const validationSchema = Yup.object().shape({
    userCountry: Yup.string().trim().required("*Country is required"),
    userUniversity: Yup.string()
      .trim()
      .required("*University name is required"),
    userMajor: Yup.string().trim().required("*University major is required"),
    userAcademicYear: Yup.string()
      .trim()
      .transform((value) => value.toUpperCase())
      .oneOf(
        ["1D", "2D", "3D", "4D", "5D", "6D", "INTERNSHIP"],
        "Must be a valid academic year (1D, 2D, 3D, etc.), or Internship"
      )
      .required("Academic year is required"),
    userGraduationYear: Yup.string().required("*Graduation year is required"),
    // phoneNumber: Yup.string()
    //   .required("Phone number is required")
    //   .matches(/^[0-9]{10,15}$/, "Phone number is not valid"),
  });

  useEffect(() => {
    const preventContextMenu = (event) => event.preventDefault();
    document.addEventListener("contextmenu", preventContextMenu);
    return () => {
      document.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const signupData = JSON.parse(localStorage.getItem("signupData"));

    const payload = {
      fullName: signupData.fullName,
      email: signupData.email,
      password: signupData.password,
      userCountry: values.userCountry.trim(),
      userUniversity: values.userUniversity.trim(),
      userMajor: values.userMajor.trim(),
      userAcademicYear: values.userAcademicYear.trim(),
      // phoneNumber: values.phoneNumber.trim(),
      userGraduationYear: values.userGraduationYear.trim(),
    };

    try {
      const response = await axios.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.message === "User registered successfully") {
        sessionStorage.setItem("isConnected", "true");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Account created successfully!",
          text: "Welcome to our community.",
          confirmButtonText: "Let's start",
          customClass: {
            popup: "custom-swal",
          },
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/home");
            localStorage.removeItem("signupData");
          }
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Registration Failed",
          customClass: {
            popup: "custom-swal",
          },
          text:
            response.data.message || "Registration failed. Please try again.",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.[0]?.description ||
        "An unexpected error occurred.";
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Error",
        text: errorMessage,
        showConfirmButton: true,
        customClass: {
          popup: "custom-swal",
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="moving">
        <div className="books">
          <img src={books} className="img-fluid " alt="books" />
        </div>
        <div className="heal">
          <img src={heal} className="img-fluid heal" alt="heal" />
        </div>
      </div>
      <div className="all">
        <Formik
          initialValues={{
            userCountry: "",
            userUniversity: "",
            userMajor: "",
            userAcademicYear: "",
            userGraduationYear: "",
            // phoneNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <div className="head text-center">
                <h1 className="fw-bold info-head">Information About You</h1>
                <p>fill in your details to get started with your account!</p>
              </div>
              <div className="d-flex justify-content-between align-items-center information">
                <div className="mb-3">
                  <label htmlFor="userCountry" className="form-label">
                    Your country
                  </label>
                  <Field
                    type="text"
                    name="userCountry"
                    placeholder="Egypt - Mansoura"
                    className="form-control"
                    id="userCountry"
                  />
                  <ErrorMessage name="userCountry" component="p" />
                </div>
                <div className="mb-3">
                  <label htmlFor="userUniversity" className="form-label">
                    University name
                  </label>
                  <Field
                    type="text"
                    name="userUniversity"
                    placeholder="Mansoura University"
                    className="form-control"
                    id="userUniversity"
                  />
                  <ErrorMessage name="userUniversity" component="p" />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center information">
                <div className="mb-3">
                  <label htmlFor="userMajor" className="form-label">
                    University major
                  </label>
                  <Field
                    type="text"
                    name="userMajor"
                    placeholder="Surgery"
                    className="form-control"
                    id="userMajor"
                  />
                  <ErrorMessage name="userMajor" component="p" />
                </div>
                <div className="mb-3">
                  <label htmlFor="userAcademicYear" className="form-label">
                    Academic year
                  </label>
                  <Field
                    type="text"
                    name="userAcademicYear"
                    placeholder="6D"
                    className="form-control"
                    id="userAcademicYear"
                  />
                  <ErrorMessage name="userAcademicYear" component="p" />
                </div>
              </div>
              {/* <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <Field name="phoneNumber">
                  {({ field, form }) => (
                    <PhoneInput
                      country={"eg"}
                      value={field.value}
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                        className: "form-control",
                      }}
                    />
                  )}
                </Field>

                <ErrorMessage name="phoneNumber" component="p" />
              </div> */}
              <div className="mb-3">
                <label htmlFor="userGraduationYear" className="form-label">
                  Graduation year
                </label>
                <Field
                  type="date"
                  name="userGraduationYear"
                  min="2025-01-01"
                  className="form-control"
                  id="userGraduationYear"
                />
                <ErrorMessage name="userGraduationYear" component="p" />
              </div>

              <div className="btns text-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn mb-3 fw-bold btn-one"
                >
                  Create
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Information;
