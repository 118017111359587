import "../css/main.css";
import "../css/responsive.css";
import "./css/admin.css";
import "./css/admin-responsive.css";
import React, { useEffect, useState } from "react";
import Loader from "../Registration/loader";
import UsersTable from "./UsersTable";
import BooksTable from "./Books/BooksTable";
import AddBooks from "./Books/AddBooks";
import CoursesTable from "./Courses/CoursesTable";
import AddCourses from "./Courses/AddCourses";
import SectionsTable from "./Sections/SectionsTable";
import AddSections from "./Sections/AddSections";
import VideosTable from "./Videos/VideosTable";
import AddVideos from "./Videos/AddVideos";
import AddSuggest from "./Suggest/AddSuggest";
import SuggestTable from "./Suggest/SuggestTable";
import AddSlider from "./Slide/AddSlider";
import SliderTable from "./Slide/SliderTable";
import SideBarAdmin from "../Layout/SideBarAdmin";
import { Helmet } from "react-helmet-async";
function Admin() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Learning Horizon - Dash Board</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <div className="box admin">
          <div className="container-fluid mt-5">
            <div className="row">
              <SideBarAdmin />
              <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                <div className="tab-content" id="v-pills-tabContent">
                  {/* start users */}
                  <div
                    className="tab-pane fade show active pills-home"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                    tabIndex="0"
                  >
                    <h1 className="mb-5 mt-5 pills-head-table ">Users Table</h1>
                    <UsersTable />
                  </div>
                  {/* end users */}

                  {/* start Add */}
                  <div
                    className="tab-pane fade"
                    id="v-pills-Material"
                    role="tabpanel"
                    aria-labelledby="v-pills-Material-tab"
                    tabIndex="0"
                  >
                    <AddSlider />
                    <div className="mt-5"></div>
                    <AddSuggest />
                    <div className="mt-5"></div>
                    <AddSections />
                    <div className="mt-5"></div>
                    <AddBooks />
                    <div className="mt-5"></div>
                    <AddCourses />
                    <div className="mt-5"></div>
                    <AddVideos />
                  </div>
                  {/* end Add */}

                  {/* start Suggest Table */}
                  <div
                    className="tab-pane fade"
                    id="v-pills-suggest"
                    role="tabpanel"
                    aria-labelledby="v-pills-suggest-tab"
                    tabIndex="0"
                  >
                    <h1 className="mb-5 mt-5 pills-head-table ">
                      Banner images table
                    </h1>
                    <SliderTable />
                    <h1 className="mb-5 mt-5 pills-head-table ">
                      Suggested Videos table
                    </h1>
                    <SuggestTable />
                  </div>
                  {/* end Suggest Table  */}

                  {/* start Books Table */}
                  <div
                    className="tab-pane fade"
                    id="v-pills-books"
                    role="tabpanel"
                    aria-labelledby="v-pills-books-tab"
                    tabIndex="0"
                  >
                    <h1 className="mb-5 mt-5 pills-head-table ">
                      Books sections table
                    </h1>
                    <SectionsTable />
                    <h1 className="mb-5 mt-5 pills-head-table">Books Table</h1>
                    <BooksTable />
                  </div>
                  {/* end Books Table  */}

                  {/* start Videos Table  */}
                  <div
                    className="tab-pane fade"
                    id="v-pills-Training"
                    role="tabpanel"
                    aria-labelledby="v-pills-Training-tab"
                    tabIndex="0"
                  >
                    <h1 className="mb-5 mt-5 pills-head-table ">
                      Courses sections table
                    </h1>
                    <CoursesTable />
                    <h1 className="mb-5 mt-5 pills-head-table">
                      Lessons Table
                    </h1>
                    <VideosTable />
                  </div>
                  {/* end Videos Table  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Admin;
