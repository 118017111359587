import "../css/settings.css";
import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-phone-input-2/lib/style.css";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router";
import CryptoJS from "crypto-js";

function Settings() {
  const [nameError, setNameError] = useState("");
  const [users, setUsers] = useState([]);
  const [matchedUser, setMatchedUser] = useState({
    id: "",
    fullName: "",
    country: "",
    university: "",
    major: "",
    academicYear: "",
    graduationYear: "",
  });

  const navigate = useNavigate();
  const secretKey = "e#J8zL5$e2f!v9@k8U%tR6^wO4z&Q3m*J9bL$7yP8";

  const decryptData = (cipherText, secretKey) => {
    try {
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null;
    }
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("*Name is required")
      .trim()
      .min(6, "*At least 6 characters"),
    country: Yup.string().trim().required("*Country is required"),
    university: Yup.string().trim().required("*University is required"),
    major: Yup.string().trim().required("*Major is required"),
    academicYear: Yup.string().trim().required("*Academic year is required"),
    graduationYear: Yup.string()
      .trim()
      .required("*Graduation year is required"),
  });

  useEffect(() => {
    const encryptedFullName =
      localStorage.getItem("9@k8U%") || sessionStorage.getItem("9@k8U%");

    const decryptedFullName = decryptData(encryptedFullName, secretKey);

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://learninghorizon.runasp.net/Api/User"
        );
        setUsers(response.data);

        const foundUser = response.data.find(
          (user) => user.fullName === decryptedFullName
        );
        if (foundUser) {
          setMatchedUser(foundUser);

          const encryptedEmail = CryptoJS.AES.encrypt(
            foundUser.email,
            secretKey
          ).toString();
          localStorage.setItem("8zL5$", encryptedEmail);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const userUrl = `https://learninghorizon.runasp.net/Api/User?id=${matchedUser.id}`;

  const handleSubmit = async (values) => {
    const trimmedValues = {
      fullName: values.fullName.trim(),
      country: values.country.trim(),
      university: values.university.trim(),
      major: values.major.trim(),
      academicYear: values.academicYear.trim(),
      graduationYear: values.graduationYear.trim(),
    };

    if (/\s/.test(trimmedValues.fullName)) {
      setNameError("Name should not contain spaces and unique.");
      return;
    }

    try {
      const allUsersResponse = await axios.get(
        "https://learninghorizon.runasp.net/Api/User"
      );

      const allUsers = allUsersResponse.data;

      const isNameTaken = allUsers.some(
        (user) =>
          user.fullName.toLowerCase() ===
            trimmedValues.fullName.toLowerCase() && user.id !== matchedUser.id
      );

      if (isNameTaken) {
        setNameError("This name is already taken.");
        return;
      } else {
        setNameError("");
      }

      Swal.fire({
        title: "Do you want to save the changes?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          popup: "custom-swal",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const updatedData = {
              fullName: trimmedValues.fullName,
              userCountry: trimmedValues.country,
              userUniversity: trimmedValues.university,
              userMajor: trimmedValues.major,
              userAcademicYear: trimmedValues.academicYear,
              userGraduationYear: trimmedValues.graduationYear,
            };

            await axios.put(userUrl, updatedData);

            localStorage.setItem("9@k8U%", trimmedValues.fullName);
            sessionStorage.setItem("9@k8U%", trimmedValues.fullName);

            Swal.fire({
              title: "Done",
              text: "Your profile updated successfully",
              icon: "success",
              confirmButtonText: "OK",
              customClass: {
                popup: "custom-swal",
              },
            });
          } catch (error) {
            Swal.fire({
              title: "Error",
              text: `An error occurred: ${
                error.response && error.response.data
                  ? JSON.stringify(error.response.data, null, 2)
                  : error.message
              }`,
              icon: "error",
              customClass: {
                popup: "custom-swal",
              },
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: `Error fetching users: ${error.message}`,
        icon: "error",
        customClass: {
          popup: "custom-swal",
        },
      });
    }
  };

  const deleteUser = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: "custom-swal",
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        html: `<p style="font-weight: bold;">This action cannot be undone, and your data will be permanently removed.</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          popup: "custom-swal",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(userUrl, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              if (!res.ok) {
                return res.text().then((text) => {
                  throw new Error(`Network response was not ok: ${text}`);
                });
              }
              return res.text();
            })
            .then(() => {
              localStorage.removeItem("9@k8U%");
              localStorage.removeItem("isConnected");
              localStorage.removeItem("savedEmail");
              localStorage.removeItem("savedPassword");
              sessionStorage.removeItem("isConnected");

              swalWithBootstrapButtons
                .fire({
                  title: "Deleted!",
                  html: `<p style="font-weight: bold;">Your account has been successfully deleted</p>`,
                  icon: "success",
                  customClass: {
                    popup: "custom-swal",
                  },
                })
                .then(() => {
                  navigate("/");
                });
            })
            .catch((error) => {
              swalWithBootstrapButtons.fire({
                title: "Error!",
                html: `<p style="font-weight: bold;">There was an error deleting <span style="color: red; font-weight: bold;"></span>: ${error.message}</p>`,
                icon: "error",
                customClass: {
                  popup: "custom-swal",
                },
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            html: `<p style="font-weight: bold;">Your account is still active and safe</p>`,
            icon: "error",
            customClass: {
              popup: "custom-swal",
            },
          });
        }
      });
  };

  return (
    <div className="settings">
      <h1 className="mb-5 pills-head pills-head-table">Edit Your Profile</h1>
      <div className="user-edit">
        <Formik
          enableReinitialize
          initialValues={{
            fullName: matchedUser.fullName.trim() || "",
            country: matchedUser.country.trim() || "",
            university: matchedUser.university.trim() || "",
            major: matchedUser.major.trim() || "",
            academicYear: matchedUser.academicYear.trim() || "",
            graduationYear: matchedUser.graduationYear.trim() || "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              {nameError && (
                <div className="alert alert-danger text-center">
                  {nameError}
                </div>
              )}
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="mb-3">
                    <label htmlFor="exampleInputText" className="form-label">
                      User name
                    </label>
                    <Field
                      type="text"
                      name="fullName"
                      placeholder="Paul Ehrlich"
                      className="form-control"
                      id="exampleInputText"
                      aria-describedby="textHelp"
                    />
                    <ErrorMessage name="fullName" component="p" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Your country
                    </label>
                    <Field
                      type="text"
                      name="country"
                      placeholder="Egypt - Mansoura"
                      className="form-control"
                      id="country"
                    />
                    <ErrorMessage name="country" component="p" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="university" className="form-label">
                      University name
                    </label>
                    <Field
                      type="text"
                      name="university"
                      placeholder="Mansoura University"
                      className="form-control"
                      id="university"
                    />
                    <ErrorMessage name="university" component="p" />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="mb-3">
                    <label htmlFor="major" className="form-label">
                      University major
                    </label>
                    <Field
                      type="text"
                      name="major"
                      placeholder="Surgery"
                      className="form-control"
                      id="major"
                    />
                    <ErrorMessage name="major" component="p" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="academicYear" className="form-label">
                      Academic year
                    </label>
                    <Field
                      type="text"
                      name="academicYear"
                      placeholder="6D"
                      className="form-control"
                      id="academicYear"
                    />
                    <ErrorMessage name="academicYear" component="p" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="graduationYear" className="form-label">
                      Graduation year
                    </label>
                    <Field
                      type="date"
                      name="graduationYear"
                      min="2025-01-01"
                      className="form-control"
                      id="graduationYear"
                    />
                    <ErrorMessage name="graduationYear" component="p" />
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-between"> */}
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-center text-lg-start text-md-start text-sm-start">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn save mt-3 fw-bold"
                  >
                    <FaEdit className="me-1" />
                    <span className=""> Save Changes</span>
                  </button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 ">
                  <button
                    type="button"
                    onClick={() =>
                      deleteUser(matchedUser.id, matchedUser.fullName)
                    }
                    disabled={isSubmitting}
                    className="btn btn-danger mt-3 fw-bold d-flex align-items-center text-center ms-lg-auto ms-md-auto ms-sm-auto"
                  >
                    <span>Delete Account</span>
                    <MdDelete className="ms-1 icon-delete" />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Settings;
