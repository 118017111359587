import "./css/registration.css";
import "./css/responsive-registration.css";
import books from "../images/books.png";
import heal from "../images/heal.png";
import lock from "../images/lock.png";
import Loader from "./loader";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";

function ForgotPassword() {
  const apiUrl = `https://learninghorizon.runasp.net/Api/Account/forgot-password`;
  const [loading, setLoading] = useState(true);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    const preventContextMenu = (event) => event.preventDefault();
    document.addEventListener("contextmenu", preventContextMenu);
    return () => {
      document.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Invalid email address")
      .required("*Email is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        apiUrl,
        { email: values.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "An email with the code has been sent. Please check your inbox.",
          confirmButtonText: "Continue",
          customClass: {
            popup: "custom-swal",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.setItem("Passcode", response.data);
            navigate("/ConfirmCode");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "The email address is not registered. Please try again.",
          confirmButtonText: "Retry",
          customClass: {
            popup: "custom-swal",
          },
        });
      }
    } catch (error) {
      setEmailError("Error sending password reset email. Please try again.");
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="moving">
            <div className="books">
              <img src={books} className="img-fluid" alt="books" />
            </div>
            <div className="heal">
              <img src={heal} className="img-fluid heal" alt="heal" />
            </div>
          </div>
          <div className="all">
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <h1 className="fw-bold mb-4">Password Recovery</h1>
                  <div className="text-center mb-3">
                    <img src={lock} className="img-fluid lock" alt="lock" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                    <ErrorMessage name="email" component="p" />
                    {emailError && <p className="text-danger">{emailError}</p>}
                  </div>
                  <div className="btns text-center">
                    <button
                      type="submit"
                      className="btn mb-2 fw-bold btn-one"
                      disabled={isSubmitting}
                    >
                      Next
                    </button>
                    <br />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  );
}

export default ForgotPassword;
