import styled from "styled-components";
import logo from "../images/Group1.png";
import support from "../images/support.png";
import React from "react";
import { HiMiniSquare3Stack3D } from "react-icons/hi2";
import { FaHome, FaBook, FaCog, FaChalkboardTeacher } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function SideBar() {
  const navigate = useNavigate();
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
      customClass: {
        popup: "custom-swal",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        localStorage.removeItem("savedEmail");
        localStorage.removeItem("isConnected");
        sessionStorage.removeItem("isConnected");
        localStorage.removeItem("9@k8U%");
        sessionStorage.removeItem("9@k8U%");
        localStorage.removeItem("8zL5$");
        sessionStorage.removeItem("8zL5$");
        navigate("/");
      }
    });
  };
  return (
    <>
      <div
        className="nav nav-side flex-column nav-pills"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <SidebarWrapper>
          <SidebarContainer>
            <CharacterImage>
              <img src={logo} alt={logo} />
            </CharacterImage>
            <NavMenu>
              <MenuItem>
                <div className="btn-sidebar mt-4 ">
                  <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    <FaHome />
                    <span className="ms-lg-2 ms-md-2 ms-sm-2 ms-1">Home</span>
                  </button>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="btn-sidebar">
                  <button
                    className="nav-link "
                    id="v-pills-Material-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Material"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Material"
                    aria-selected="false"
                  >
                    <HiMiniSquare3Stack3D className="" />
                    <span className="ms-lg-2 ms-md-2 ms-sm-2">Material</span>
                  </button>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="btn-sidebar">
                  <button
                    className="nav-link"
                    id="v-pills-books-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-books"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-books"
                    aria-selected="false"
                  >
                    <FaBook className="" />

                    <span className="ms-lg-2 ms-md-2 ms-sm-2">Books</span>
                  </button>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="btn-sidebar">
                  <button
                    className="nav-link"
                    id="v-pills-Session-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Session"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Session"
                    aria-selected="false"
                  >
                    <FaChalkboardTeacher className="" />
                    <span className="ms-lg-2 ms-md-2 ms-sm-2">Session</span>
                  </button>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="btn-sidebar">
                  <button
                    className="nav-link"
                    id="v-pills-Settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Settings"
                    aria-selected="false"
                  >
                    <FaCog className="" />
                    <span className="ms-lg-2 ms-md-2 ms-sm-2">Settings</span>
                  </button>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="btn-sidebar">
                  <button
                    onClick={handleLogout}
                    className="nav-link logout"
                    type="button"
                  >
                    <ImExit />
                    <span className="ms-lg-2 ms-md-2 ms-sm-2">Logout</span>
                  </button>
                </div>
              </MenuItem>
            </NavMenu>
            <CharacterImage>
              {/* <Link
                  to="https://wa.me/2001152960333?"
                  target="blank"
                  rel="noopener"
                ></Link> */}
              <img className="mt-2" src={support} alt={support} />
            </CharacterImage>
          </SidebarContainer>
        </SidebarWrapper>
      </div>
    </>
  );
}

export default SideBar;

const SidebarWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  transition: width 0.3s ease;
  z-index: 999;

  @media (max-width: 705px) {
    width: 80px;
    left: 13%;
    transform: translateX(-50%);
  }
`;

const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 18px;
  span {
    display: inline-block;
    transition: opacity 0.3s ease, width 0.3s ease;
    opacity: 1;
    white-space: nowrap;
    @media (max-width: 705px) {
      opacity: 0;
      width: 0;
    }
  }
`;

const CharacterImage = styled.div`
  img {
    height: auto;
    width: 100px;
    margin: auto;
    cursor:pointer;
  }

  @media (max-width: 992px) {
    img {
      width: 77px;
    }
  @media (max-width: 705px) {
    img {
      width: 70px;
    }
  }
`;
