import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

function UsersTable() {
  const api_url = "https://learninghorizon.runasp.net/Api/User";

  const [users, setUsers] = useState([]);
  const getData = () => {
    fetch(api_url)
      .then((res) => res.json())
      .then((data) => {
        const sortedUsers = data.sort((a, b) =>
          a.fullName.localeCompare(b.fullName)
        );
        setUsers(sortedUsers);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const deleteUser = (userId, fullName) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: "custom-swal",
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        html: `<p style="font-weight: bold;">You won't be able to revert this! Do you want to remove <span style="color: red; font-weight: bold;">${fullName}</span>?</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          popup: "custom-swal",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(`https://learninghorizon.runasp.net/Api/User?id=${userId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              if (!res.ok) {
                return res.text().then((text) => {
                  throw new Error(`Network response was not ok: ${text}`);
                });
              }
              return res.text();
            })
            .then(() => {
              localStorage.removeItem("fullName");
              localStorage.removeItem("isConnected");
              localStorage.removeItem("savedEmail");
              localStorage.removeItem("savedPassword");
              sessionStorage.removeItem("isConnected");
              getData();

              swalWithBootstrapButtons.fire({
                title: "Deleted!",
                html: `<p style="font-weight: bold;">${fullName} has been deleted</p>`,
                icon: "success",
                customClass: {
                  popup: "custom-swal",
                },
              });
            })
            .catch((error) => {
              swalWithBootstrapButtons.fire({
                title: "Error!",
                html: `<p style="font-weight: bold;">There was an error deleting <span style="color: red; font-weight: bold;">${fullName}</span>: ${error.message}</p>`,
                icon: "error",
                customClass: {
                  popup: "custom-swal",
                },
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            html: `<p style="font-weight: bold;"><span style="color: #007bff; font-weight: bold;">${fullName}</span> is safe</p>`,
            icon: "error",
            customClass: {
              popup: "custom-swal",
            },
          });
        }
      });
  };

  const groupedUsers = users.reduce((acc, user) => {
    const firstLetter = user.fullName.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(user);
    return acc;
  }, {});

  const allUsers = users.length;
  return (
    <div className="table-responsive">
      <div className="mb-3 ">
        <h3 className="">Total Users: {allUsers}</h3>
      </div>
      <table className="table">
        <thead>
          <tr className="">
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            {/* <th scope="col">Phone </th> */}
            <th scope="col">Country</th>
            <th scope="col">University</th>
            <th scope="col">Major</th>
            <th scope="col">AcademicYear</th>
            <th scope="col">GraduationYear</th>
            <th scope="col">Operation</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedUsers).map((letter, index) => (
            <React.Fragment key={letter}>
              <tr className="">
                <td
                  colSpan="12"
                  style={{
                    backgroundColor: "#f0f0f0",
                    fontWeight: "bold",
                  }}
                >
                  {letter}
                </td>
              </tr>
              {groupedUsers[letter].map((user, userIndex) => (
                <tr key={user.id}>
                  <td>{userIndex + 1}</td>
                  <td>{user.fullName}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  {/* <td>
                    <a
                      href={`https://wa.me/${user.PhoneNumber}?`}
                      target="blank"
                      rel="noopener"
                    >
                      {user.PhoneNumber ? user.PhoneNumber : "5465456456"}
                    </a>
                  </td> */}
                  <td>{user.country}</td>
                  <td>{user.university}</td>
                  <td>{user.major}</td>
                  <td>{user.academicYear}</td>
                  <td className="year">{user.graduationYear}</td>
                  <td>
                    <button
                      className="operation btn btn-danger btn-sm"
                      onClick={() => deleteUser(user.id, user.fullName)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}

              {index < Object.keys(groupedUsers).length - 1 && (
                <tr>
                  <td colSpan="9">
                    <div style={{ margin: "20px 0" }}></div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersTable;
