import "../css/responsive.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function Material() {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const navigate = useNavigate();

  const getCourses = `https://learninghorizon.runasp.net/Api/Course/GetAllCourses`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(getCourses);
        const data = await response.json();
        setCourses(data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
  };

  const handleLinkClick = (courseId) => {
    const fullName =
      localStorage.getItem("9@k8U%") || sessionStorage.getItem("9@k8U%");
    if (fullName) {
      window.open(`/home/course-videos/${courseId}`, "_blank");
    } else {
      navigate(`/login`);
    }
  };

  const filteredCourses = courses.filter((course) => {
    const matchesSearchTerm = course.courseTittle
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSelectedCourse =
      !selectedCourse || course.courseId === selectedCourse.courseId;

    return matchesSearchTerm && matchesSelectedCourse;
  });

  return (
    <div className="material">
      <div className="book-input">
        <label className="search-icon" htmlFor="search1">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </label>
        <input
          className="form-control search"
          id="search1"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="sections mt-4">
        <button
          className={`btn mt-2 me-2 ${!selectedCourse ? "active" : "back"}`}
          onClick={() => setSelectedCourse(null)}
        >
          All Courses
        </button>
        {courses.map((course) => (
          <button
            key={course.courseId}
            className={`btn mt-2 me-2 ${
              selectedCourse?.courseId === course.courseId ? "active" : "back"
            }`}
            onClick={() => handleCourseClick(course)}
          >
            {course.courseTittle}
          </button>
        ))}
      </div>
      <div className="row mt-5">
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course) => (
            <div
              className="col-lg-4 col-md-6 col-sm-12 mb-4"
              key={course.courseId}
            >
              <div className="box text-bg-dark pb-3">
                <img
                  src={`https://learninghorizon.runasp.net/Api/Book/GetBookFile?path=${course.courseImagePath}`}
                  className="card-img img-fluid"
                  alt={course.courseTittle}
                />
                <div className="d-flex align-items-center justify-content-start card-head">
                  <span className="me-1 mb-1">
                    <FaUserCircle />
                  </span>
                  <h3 className="card-creator mt-2">{course.courseCreator}</h3>
                </div>
                <div className="card-info p-2 mt-5">
                  <h5 className="card-title mb-3">{course.courseTittle}</h5>
                  <div className="d-flex align-items-center">
                    <p className="card-price mt-3 me-auto">
                      {course.coursePrice !== 0
                        ? `${course.coursePrice} LE`
                        : "Free"}
                    </p>
                    <button
                      className="btn d-flex align-items-center view"
                      onClick={() => handleLinkClick(course.courseId)}
                    >
                      View
                      <FontAwesomeIcon className="ms-2" icon={faChevronRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-center">
              No courses found matching your search criteria.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Material;
