import styled from "styled-components";
import logo from "../images/Group1.png";

import React from "react";
import { BiSolidVideos } from "react-icons/bi";
import { MdLibraryAdd } from "react-icons/md";
import { FaPhotoFilm } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { FaUsers, FaBook } from "react-icons/fa";
import { ImExit } from "react-icons/im";

import Swal from "sweetalert2";

function SideBarAdmin() {
  const navigate = useNavigate();
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
      customClass: {
        popup: "custom-swal",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("isAdmin");
        sessionStorage.removeItem("isAuthenticated");
        sessionStorage.removeItem("isAdmin");
        navigate("/");
      }
    });
  };
  return (
    <>
      <div className="col-lg-2 col-md-2 col-sm-2 col-2 sidebar">
        <div
          className="nav nav-side flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <SidebarWrapper>
            <SidebarContainer>
              <CharacterImage>
                <img src={logo} alt={logo} />
              </CharacterImage>
              <NavMenu>
                <MenuItem>
                  <div className="btn-sidebar mt-4 ">
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <FaUsers />
                      <span className="ms-lg-2 ms-md-2 ms-sm-2 ms-1">
                        Users
                      </span>
                    </button>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="btn-sidebar">
                    <button
                      className="nav-link "
                      id="v-pills-Material-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Material"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Material"
                      aria-selected="false"
                    >
                      <MdLibraryAdd className="" />
                      <span className="ms-lg-2 ms-md-2 ms-sm-2">Add</span>
                    </button>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="btn-sidebar">
                    <button
                      className="nav-link mb-1"
                      id="v-pills-suggest-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-suggest"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-suggest"
                      aria-selected="false"
                    >
                      <FaPhotoFilm />
                      <span className="ms-lg-2 ms-md-2 ms-sm-2">Suggests</span>
                    </button>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="btn-sidebar">
                    <button
                      className="nav-link"
                      id="v-pills-books-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-books"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-books"
                      aria-selected="false"
                    >
                      <FaBook className="" />
                      <span className="ms-lg-2 ms-md-2 ms-sm-2">Books</span>
                    </button>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="btn-sidebar">
                    <button
                      className="nav-link mb-1"
                      id="v-pills-Training-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Training"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Training"
                      aria-selected="false"
                    >
                      <BiSolidVideos className="" />
                      <span className="ms-lg-2 ms-md-2 ms-sm-2">Videos</span>
                    </button>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="btn-sidebar">
                    <button
                      onClick={handleLogout}
                      className="nav-link logout"
                      type="button"
                    >
                      <ImExit />
                      <span className="ms-lg-2 ms-md-2 ms-sm-2">Logout</span>
                    </button>
                  </div>
                </MenuItem>
              </NavMenu>
              <CharacterImage></CharacterImage>
            </SidebarContainer>
          </SidebarWrapper>
        </div>
      </div>
    </>
  );
}

export default SideBarAdmin;

const SidebarWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  transition: width 0.3s ease;
  z-index: 999;

  @media (max-width: 992px) {
    width: 80px;
    left: 9%;
    transform: translateX(-50%);
  }

  @media (max-width: 705px) {
    width: 80px;
    left: 9%;
    transform: translateX(-50%);
  }
`;

const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 18px;
  span {
    display: inline-block;
    transition: opacity 0.3s ease, width 0.3s ease;
    opacity: 1;
    white-space: nowrap;
    @media (max-width: 705px) {
      opacity: 0;
      width: 0;
    }
  }
`;

const CharacterImage = styled.div`
  img {
    height: auto;
    width: 100px;
    margin: auto;
  }

  @media (max-width: 992px) {
    img {
      width: 77px;
    }
  @media (max-width: 705px) {
    img {
      width: 70px;
    }
  }
`;
