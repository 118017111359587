import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BiErrorAlt } from "react-icons/bi";
import CryptoJS from "crypto-js";
import "video-react/dist/video-react.css";
import {
  Player,
  ForwardControl,
  ControlBar,
  ReplayControl,
  PlaybackRateMenuButton,
} from "video-react";
import Loader from "../Registration/loader";
import Material from "./Material";
import Books from "./Books";
import SlideHome from "../Layout/SlideHome";
import Settings from "./Settings";
import SideBar from "../Layout/SideBar";
import { Helmet } from "react-helmet-async";

function Home() {
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();

  const secretKey = "e#J8zL5$e2f!v9@k8U%tR6^wO4z&Q3m*J9bL$7yP8";

  const decryptData = (cipherText, secretKey) => {
    try {
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          "https://learninghorizon.runasp.net/Api/Suggest"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        const videoData = await Promise.all(
          data.map(async (video) => {
            const videoFileResponse = await fetch(
              `https://learninghorizon.runasp.net/Api/Lesson/GetLessonFile?path=${encodeURIComponent(
                video.videoPath
              )}`
            );
            if (!videoFileResponse.ok) {
              throw new Error("Network response was not ok");
            }
            return {
              url: videoFileResponse.url,
              title: video.videoName,
            };
          })
        );

        const localVideoUrl = localStorage.getItem("homeVideo_1");
        const localVideoUrl2 = localStorage.getItem("homeVideo_2");
        if (localVideoUrl) {
          videoData.unshift({
            url: localVideoUrl,
            title: "Local Video 1",
          });
        }
        if (localVideoUrl2) {
          videoData.unshift({
            url: localVideoUrl2,
            title: "Local Video 2",
          });
        }

        setVideos(videoData);
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
    const encryptedFullName =
      localStorage.getItem("9@k8U%") || sessionStorage.getItem("9@k8U%");
    if (encryptedFullName) {
      const decryptedFullName = decryptData(encryptedFullName, secretKey);
      setUserName(decryptedFullName);
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("9@k8U%") && !sessionStorage.getItem("9@k8U%")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>
          Learning Horizon - كورسات وكتب طبية تعليمية لتحسين مهاراتك الأكاديمية
          والمهنية
        </title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <div className="box home">
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-3 col-3 text-center">
                <SideBar />
              </div>
              <div className="col-lg-10 col-md-9 col-sm-9 col-9">
                <div className="tab-content" id="v-pills-tabContent">
                  {/* start home */}
                  <div
                    className="tab-pane fade show active pills-home"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                    tabIndex="0"
                  >
                    <h1 className="mb-5  pills-head pills-head-table">
                      Welcome, {userName}
                    </h1>
                    <SlideHome />
                    <h1 className="mb-5 mt-5 pills-head recommended">
                      Recommended for You
                    </h1>
                    <div className="suggested-videos">
                      <div className="row">
                        {videos.length > 0 ? (
                          videos.map((video, index) => (
                            <div
                              className="col-lg-6 col-md-6 col-12"
                              key={index}
                            >
                              <div className="mb-3">
                                <Player
                                  playsInline
                                  src={video.url}
                                  className="video-player"
                                >
                                  <ControlBar autoHide={true}>
                                    <ForwardControl seconds={10} order={3.1} />
                                    <ReplayControl seconds={10} order={2.1} />
                                    <PlaybackRateMenuButton
                                      rates={[0.5, 1, 1.5, 2]}
                                    />
                                  </ControlBar>
                                </Player>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No videos to display</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* end home */}

                  {/* start material */}
                  <div
                    className="tab-pane fade Material-tab"
                    id="v-pills-Material"
                    role="tabpanel"
                    aria-labelledby="v-pills-Material-tab"
                    tabIndex="0"
                  >
                    <Material />
                  </div>
                  {/* end material */}

                  {/* start session */}
                  <div
                    className="tab-pane fade"
                    id="v-pills-Session"
                    role="tabpanel"
                    aria-labelledby="v-pills-Session-tab"
                    tabIndex="0"
                  >
                    <div className="text-center text-capitalize fw-bold d-flex justify-content-center align-items-center">
                      <BiErrorAlt className="me-1" />
                      Not Available Now
                    </div>
                  </div>
                  {/* end session */}

                  {/* start books */}
                  <div
                    className="tab-pane fade books-tab"
                    id="v-pills-books"
                    role="tabpanel"
                    aria-labelledby="v-pills-books-tab"
                    tabIndex="0"
                  >
                    <Books />
                  </div>
                  {/* end books */}

                  {/* start settings */}
                  <div
                    className="tab-pane fade"
                    id="v-pills-Settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-Settings-tab"
                    tabIndex="0"
                  >
                    <Settings />
                  </div>
                  {/* end settings */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
